<template>
  <b-container class="bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row no-gutters>
        <b-col> </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <h1 class="text-left mb-0">Create account group account modal demo</h1>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col class="text-center"><b-button variant="primary" class="my-5" @click="onModalOpenClick">Open modal</b-button></b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <p>Emitted data:</p>
          <p>{{ modalEmittedData }}</p>
        </b-col>
      </b-row>
    </div>

    <create-account-group-account-modal ref="AGModal" @performed="onModalPerformed" />
  </b-container>
</template>

<script>
import CreateAccountGroupAccountModal from '@/components/modals/CreateAccountGroupAccountModal'

export default {
  name: 'CreateAccountGroupModal',
  components: { CreateAccountGroupAccountModal },
  data () {
    return {
      modalEmittedData: null
    }
  },
  methods: {
    onModalOpenClick () {
      this.$refs.AGModal.show()
    },
    onModalPerformed (data) {
      this.modalEmittedData = data
      this.$refs.AGModal.hide()
    }
  }
}
</script>
